import React from 'react';
import ConfigurationRow from './ConfigurationRow';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import ApiService from "./ApiService";
import TextField from "@material-ui/core/TextField";
import TablePagination from '@material-ui/core/TablePagination';

export default class ConfigurationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      searchText: '',
      limit: 10,
      page: 0,
      configurations: [],
      numOfConfigs: 0
    };
    this.handleEdit = this.handleEdit.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.refreshConfigurations = this.refreshConfigurations.bind(this);
  }

  handleEdit(configId) {
    if(this.props.onEdit) {
      this.props.onEdit(configId);
    }
  }

  handleError(err) {
    if (this.props.onError) {
      this.props.onError(err);
    }
  }

  async handleSearch(searchText) {
    if (this.state.searchText !== searchText) {
      this.setState({searchText: searchText}, () => {this.refreshConfigurations()});
    }
  }

  handlePageChange(evt, page) {
    this.setState({page: page, offset: this.state.limit * page}, () => this.refreshConfigurations());
  }

  handleLimitChange(evt) {
    this.setState({limit: parseInt(evt.target.value, 10), page: 0, offset: 0}, () => this.refreshConfigurations());
  }

  async refreshConfigurations() {
    try {
      const data = await ApiService.getConfigList(this.state.searchText, this.state.limit, this.state.offset);
      this.setState({
        isLoaded: true,
        configurations: data && data.configurations ? data.configurations : [],
        numOfConfigs: data && data.numberOfConfigurations ? data.numberOfConfigurations : 0
      });
    } catch (err) {
      this.handleError(err);
      this.setState({
        isLoaded: true,
      });
    }
  }

  async componentDidMount() {
    await this.refreshConfigurations();
  }

  render() {
    const { isLoaded, configurations } = this.state;
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <TextField id="outlined-search" label="Search" type="search" variant="outlined" size="small" onChange={(evt) => this.handleSearch(evt.target.value)}/>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell/>
                  <TableCell>Name</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Schedule</TableCell>
                  <TableCell>FileFormat</TableCell>
                  <TableCell>Protocol</TableCell>
                  <TableCell>Last Run</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody >
                {configurations.map((row) => (
                    <ConfigurationRow key={row.id} config={row} onEdit={this.handleEdit} onError={this.handleError} onRefresh={this.refreshConfigurations}/>
                ))}
              </TableBody>
            </Table>
            {configurations.length <= 0 &&
              <p className="no-data">No data for preview</p>
            }
          </TableContainer>
          <TablePagination
            component="div"
            count={this.state.numOfConfigs}
            page={this.state.page}
            onChangePage={this.handlePageChange}
            rowsPerPage={this.state.limit}
            onChangeRowsPerPage={this.handleLimitChange}
          />
          </div>
      );
    }
  }
}